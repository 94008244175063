<template>
  <div class="page_user--login">
    <div class="login-form">
      <div class="inner-wrap">
        <div class="form-group">
          <div class="label">{{ $t("field.system_user.username") }}</div>
          <InputBox
            v-model="username"
            type="filled"
            :placeholder="`${$t('placeholder.please_input')}${$t('field.system_user.username')}`"
            @keyup.enter.native="login"/>
        </div>
        <div class="form-group">
          <div class="label">{{ $t("field.system_user.password") }}</div>
          <InputBox
            v-model="password"
            type="filled"
            :placeholder="`${$t('placeholder.please_input')}${$t('field.system_user.password')}`"
            inputType="password"
            @keyup.enter.native="login"
            view-password/>
        </div>
        <div class="message">{{ error }}</div>
        <div class="action-btn">
          <button @click="login" :loading="load">{{ $t("button.login") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    resetError() {
      if(this.error) {
        this.error = "";
      }
    },
    login() {
      // break function if login info incomplete
      if(this.username.length == 0 || this.password.length == 0) {
        this.error = "Login information incomplete";
        return;
      }
      this.load = true;
      this.needLoading(true);
      this.$axios.post("/authenticate", {
        username: this.username,
        password: this.password
      }).then(res=>{
        // perform login if success
        // save login token
        let token = res.headers.authorization;
        console.log(token);
        localStorage.lt = token;
        this.$axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        console.log(this.$axios.defaults.headers.common);
        this.$store.dispatch("user/fetchPermissions").then(res=>{
          this.$store.commit("status/setReady", true);
          this.$store.commit("status/setLoggedIn", true);
        });
        // go to home page
        this.go("");
      }).catch(err=>{
        console.log(err.response);
        if(typeof err.response == "undefined") {
          this.error = this.$t("message.network_error");
        }else if(err.response.status == 401) {
          this.error = this.$t("message.login_failed");
          if(err?.response?.data?.errors && err?.response?.data?.errors.includes("LOGIN_FAIL_ATTEMPTS_REMAINING")) {
            this.$notify({
              msg: this.$tc(`error.code.LOGIN_FAIL_ATTEMPTS_REMAINING`,null,{ x: err?.response?.data?.value }),
              type: "error"
            });
          }
        }else if(err.response.status == 500) {
          this.error = this.$t("message.server_error");
        }else if(err.response.status == 400) {
          console.log(err.response.data);
          err.response.data.errors.map(e=>{
            this.$notify({
              msg: this.$t(`error.code.${e}`),
              type: "error"
            });
          });
        }
      }).then(()=>{
        this.load = false;
        this.needLoading(false);
      });
    }
  },
  computed: {

  },
  data() {
    return {
      username: "",
      password: "",
      error: "",
      load: false
    }
  }
}
</script>

<style lang="scss">
.page_user--login{
  // background: #172145;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 50px;
  .login-form{
    width: 550px;
    height: 300px;
    background: #fcfcfc;
    // border: 1px solid  var(--layout-border-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    // box-shadow: 1px 1px 9px #ccc;
    .inner-wrap{
      width: 400px;
      display: flex;
      flex-direction: column;
      .label{
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .form-group{
      margin-bottom: 15px;
    }
    .message{
      color: red;
      font-size: small;
    }
    .action-btn{
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      button{
        height: 40px;
        width: 100px;
        background: #4379de;
        border: 0px;
        color: white;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>